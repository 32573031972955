<template>
    <main id="liste" v-cloak>
        <HeaderTab 
            :title="$t('acte.historique_actes')"
            :swiper_tabs="swiper_tabs" 
            :swiper_action="handleSwiperChange" 
        />
        
        <div id="content">
            <shutter-panel ref="shutterPanel" />
        </div>

        <ModalDemandeAnalyse
            ref="modal_demande_analyse"
        />

        <ModalAskPaillettes
            ref="modal_ask_paillettes"
            :callback_confirm="goToDeleteActes"
        />
    </main>
</template>


<script>
    import ActeMixin from "@/mixins/Actes.js";
	import ActeTypeMixin from "@/mixins/ActeType.js"
    import Navigation from "@/mixins/Navigation.js";
    import Shutter from '@/mixins/Shutter.js'
	import ShutterAct from "@/mixins/shutters-manager/Acts.js"
    import StockSemence from '@/mixins/StockSemence.js'
    import Common from '@/assets/js/common'

	export default {
		name: "acteListeBeta",
		mixins: [ActeMixin, ActeTypeMixin, Navigation, Shutter, StockSemence, ShutterAct],
		data () {
			return {
				swiper_tabs: [{
                        label: 'acte.tous',
                        active: true,
                        name: ''
                    }
                ],
                actual_tab: {},
                actes_groupes: [],
                show_main_vue: true,
                interacted_actes: [],

                events_tab: {
                    'TableAction::DeleteActes': this.checkDeleteActes, // this.applyDeleteActes,
                    'TableAction::goToAnalyse': this.openModalAnalyse,
                    'TableAction::goToDupliqueActe': this.dupliqueActe
                },

                actes_to_delete: [],
                actes_iac: []
			}
        },
        created() {
            this.init_component()
		},
		methods: {
            async init_component() {
                this.actes_groupes = (await this.getActesGroupes(true, true))
					.filter(groupe => groupe.actestypes.some(type => type.active))

				// const actestypes = await this.getUsefullActTypesFinished()
    //             this.actes_groupes = this.actes_groupes.filter(ag => actestypes.findIndex(at => at.actestype_codegroupe === ag.actesgroupe_code) !== -1)

                this.actes_groupes.forEach(acte_groupe => {
                    this.swiper_tabs.push({
                        label: 'acte.'+acte_groupe.actesgroupe_code,
                        active: false,
                        name: acte_groupe.actesgroupe_label
                    })
				})

                this.shutterPanel().open(this.shutters['act-history-beta'])

                if(this.routeTab) {
					 this.actual_tab = this.swiper_tabs.find(tab => tab.name === this.routeTab)
				} else {
	                this.actual_tab = this.swiper_tabs[0]
				}

                this.table_busy = false
            },

            handleSwiperChange(tab) {
				const actual_route = this.$route.name
				if (this.$route.params.tab !== tab.name) {
					this.$router.replace({ name: actual_route, params: { tab: tab.name }})
				}
			},

			changeTab(tab) {
                this.actual_tab.active = false
                this.actual_tab = tab
                tab.active = true
                this.shutterPanel().update('act-history-beta', 'actesTypes', this.getGroupActesTypes(this.actual_tab.name))
                this.shutterPanel().update('act-history-beta', 'isPerfsTab', this.isPerfsTab)
                this.closeAllShutters()
			},

            openModalAnalyse(actes) {
                this.$refs.modal_demande_analyse.openModal(actes)
            },

            async checkDeleteActes(actes) {
                this.actes_to_delete = actes
                if (!this.$store.state.userAccess.hasBreedingAccess || !window.navigator.onLine) {
                    this.goToDeleteActes()
                    return false
                }

                const iac = await this.getActeTypeIAC()
                let has_iac = false
                this.actes_iac = []

                const collecte = await this.getActeTypeCollecte()
                let has_collecte = false

                await Common.asyncForEach(actes, async (acte) => {
                    if(acte.actes_actestype == iac.actestype_id) {
                        const paillettes = await this.loadStockUsedByActe(acte.actes_id)
                        if(paillettes.length > 0) {
                            has_iac = true
                            this.actes_iac.push(acte.actes_id)
                        }
                    }
                    else if (acte.actes_actestype == collecte.actestype_id) {
                        const embryons = await this.getCollecteEmbryons(acte.actes_id)
                        if(embryons.filter(embryon => embryon.embryon_transplantation).length > 0) {
                            has_collecte = true
                        }
                    }
                })
                if(has_collecte) {
                    this.failureToast("toast.collecte_embryon_transplantation")
                    return false
                }
                else if(has_iac) {
                    this.$refs.modal_ask_paillettes.openModal()
                }
                else {
                    this.goToDeleteActes()
                }
            },

            refreshTable() {
                this.shutterPanel().reload('act-history-beta')
			},

            goToDeleteActes() {
                this.applyDeleteActes(this.actes_to_delete)

                if (this.$store.state.userAccess.hasBreedingAccess && window.navigator.onLine) {
                    this.actes_iac.forEach(acte => {
                        this.cancelActeUseSemence(acte)
                    })
                }
            },

            async dupliqueActe(acte_id) {
               const acte = await this.getActeFromId(acte_id, 'withContactAndArticles')
               this.setupActDuplication(acte)
            },

            /* Close all shutters */
            closeAllShutters() {
                this.shutterPanel().keepFirst()
            },

            onShuttersSubmit() {
                this.shutterPanel().reload('act-history-beta')
                this.closeAllShutters()
            },
		},
        computed: {
            shutters() {
                return {
                    'act-history-beta': {
                        name: 'act-history-beta',
                        title: this.$t('acte.vos_actes'),
						cmpPath: 'components/ShutterScreens/Act/HistoryActBeta',
                        props: {
							actesTypes: this.getGroupActesTypes(this.actual_tab.name),
							actesTypesGyneco: null,
                            isPerfsTab: this.isPerfsTab,
                        }
                    }
                }
            },
            routeTab() {
				return this.$route.params.tab
			},
			perfsTabName() {
				return 'PERFORMANCE'
			},
            isPerfsTab() {
                return this.actual_tab.name == this.perfsTabName
            }
		},
        watch: {
			routeTab(tab_name) {
				const tab = this.swiper_tabs.find(tab => tab.name == tab_name)
				if(tab) this.changeTab(tab)
			}
		},
		components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            ModalDemandeAnalyse: () => import('@/components/Modals/ModalDemandeAnalyse'),
            ModalAskPaillettes: () => import('@/components/Modals/ModalAskPaillettes')
		}
	}
</script>
